import * as React from 'react';
import parse from 'html-react-parser';

import QrCode from '../Components/QrCode';


import ConfigContext from '../ConfigContext';


export default function Footer(props) {

	const {texts, url} = props

	const { currentLanguage } = React.useContext(ConfigContext);

	const language = texts;

	return (
		<div className="d-flex mt-5 mb-3 footer justify-content-around flex-wrap container align-self-baseline">
			<div className="p-2">
				{parse(language[currentLanguage].footer.col1)}
			</div>
			<div className="p-2">
				{parse(language[currentLanguage].footer.col2)}
			</div >
			<div className="p-2">
				{parse(language[currentLanguage].footer.col3)}
			</div>
			<div className="p-2 text-right">
				<QrCode url={url} />
			</div>
		</div>
	);

}