import React from "react";
import { Container, Row, Col } from 'react-bootstrap';
import parse from 'html-react-parser';

import imgRun from '../Assets/icons/run.png';
import imgAccess from '../Assets/icons/access.png';
import imgPhoto from '../Assets/icons/photo.png';
import imgPhone from '../Assets/icons/phone.png';
import imgHands from '../Assets/icons/hands.png';


import ConfigContext from '../ConfigContext';


function safety_images(texts) {
	if (texts.length <= 2) {
		return (
			<Row className="align-items-start justify-content-between">
				<Col xs={12} sm={6}>
					<Row>
						<Col xs="auto">
							<img src={imgPhoto} alt="" />
						</Col>
						<Col>
							{texts[0]}
						</Col>
					</Row>
				</Col>
				<Col className="mt-4 mt-sm-auto" xs={12} sm={6}>
					<Row>
						<Col xs="auto">
							<img src={imgHands} alt="" />
						</Col>
						<Col>
							{texts[1]}
						</Col>
					</Row>
				</Col>
			</Row>
		);
	} else {
		return (
			<div>
				<Row className="align-items-start justify-content-between">
					<Col xs={12} sm={6}>
						<Col xs="auto">
							<img src={imgAccess} alt="" />
						</Col>
						<Col>
							{texts[0]}
						</Col>
					</Col>
					<Col className="mt-4 mt-sm-auto" xs={12} sm={6}>
						<Col xs="auto">
							<img src={imgPhoto} alt="" />
						</Col>
						<Col>
							{texts[1]}
						</Col>
					</Col>
				</Row>
				<br />
				<Row className="align-items-start justify-content-between">
					<Col xs={12} sm={6}>
						<Col xs="auto">
							<img src={imgPhone} alt="" />
						</Col>
						<Col>
							{texts[2]}
						</Col>
					</Col>
					<Col className="mt-4 mt-sm-auto" xs={12} sm={6}>
						<Col xs="auto">
							<img src={imgHands} alt="" />
						</Col>
						<Col>
							{texts[3]}
						</Col>
					</Col>
				</Row>
			</div>
		);

	}
}

function contact_persons_table(texts) {
	if (texts.length === 2) {
		return(
			<Row className="align-items-start justify-content-between">
				<Col>
					<table className="table-important">
						<tbody>
							{parse(texts[0])}
						</tbody>
					</table>
				</Col>
				<Col className="mt-4 mt-sm-0">
					{parse(texts[1])}
				</Col>
			</Row>
		);
	}
}

function emergency_plan_table(texts) {
	if (texts.length === 3) {
		return(
			<Row className="align-items-end justify-content-between">
				<Col xs={12} sm={6}>
					{parse(texts[0])}
					<div className="table-emergency">{parse(texts[1])}</div>
				</Col>
				<Col className="mt-4 mt-sm-auto" xs={12} sm={6}>
					<Row>
						<Col xs="auto">
							<img src={imgRun} alt="" />
						</Col>
						<Col>
							{parse(texts[2])}
						</Col>
					</Row>
				</Col>
			</Row>
		);
	}
}


export default function Page(props) {

	const {texts, setActiveView} = props;

	const { currentLanguage } = React.useContext(ConfigContext);

	const language = texts;


	let compliance_safety_texts = [];
	if (language[currentLanguage].texts.compliance_section_2 !== "") {
		compliance_safety_texts = JSON.parse(language[currentLanguage].texts.compliance_section_2);
	}

	let compliance_contact_texts = [];
	if (language[currentLanguage].texts.compliance_section_4 !== "") {
		compliance_contact_texts = JSON.parse(language[currentLanguage].texts.compliance_section_4);
	}

	let compliance_emergency_texts = [];
	if (language[currentLanguage].texts.compliance_section_5 !== "") {
		compliance_emergency_texts = JSON.parse(language[currentLanguage].texts.compliance_section_5);
	}


	return (
		<Container className="mt-4 flex-grow-1">
			<Row>
				<Col className="text-left d-flex">
					<div><button type="button" className="btn btn-secondary btn-sm" onClick={() => setActiveView("disclosure")}>&lt;</button></div>
					<div className="ml-3"><h4>{parse(language[currentLanguage].titles.compliance_main)}</h4></div>
				</Col>
			</Row>
			<Row className="pt-4">
				<Col>
					<section id="section-1" className="mb-5">
						<Row>
							<Col>
								{ language[currentLanguage].titles.compliance_section_1 !== "" &&
									<h6>{parse(language[currentLanguage].titles.compliance_section_1)}</h6>
								}
								{ language[currentLanguage].texts.compliance_section_1 !== "" &&
									parse(language[currentLanguage].texts.compliance_section_1)
								}
							</Col>
						</Row>
					</section>
					<section id="section-2" className="mb-5">
						<Row>
							<Col>
								{ language[currentLanguage].titles.compliance_section_2 !== "" &&
									<h6>{parse(language[currentLanguage].titles.compliance_section_2)}</h6>
								}
								{
									safety_images(compliance_safety_texts)
								}
							</Col>
						</Row>
					</section>
					<section id="section-3" className="mb-5">
						<Row>
							<Col>
								{ language[currentLanguage].titles.compliance_section_3 !== "" &&
									<h6>{parse(language[currentLanguage].titles.compliance_section_3)}</h6>
								}
								{ language[currentLanguage].texts.compliance_section_3 !== "" &&
									parse(language[currentLanguage].texts.compliance_section_3)
								}
							</Col>
						</Row>
					</section>
					<section id="section-4" className="mb-5">
						<Row>
							<Col>
								{ language[currentLanguage].titles.compliance_section_4 !== "" &&
									<h6>{parse(language[currentLanguage].titles.compliance_section_4)}</h6>
								}
								{
									contact_persons_table(compliance_contact_texts)
								}
							</Col>
						</Row>
					</section>
					<section id="section-5" className="mb-5">
						<Row>
							<Col>
								{ language[currentLanguage].titles.compliance_section_5 !== "" &&
									<h6>{parse(language[currentLanguage].titles.compliance_section_5)}</h6>
								}
								{
									emergency_plan_table(compliance_emergency_texts)
								}
							</Col>
						</Row>
					</section>
				</Col>
			</Row>
			<Row className="justify-content-center">
				<Col className="mt-4" xs={12} sm={6} lg="auto">
					<button type="button" className="btn btn-primary w-100" onClick={() => setActiveView("signature")}>{language[currentLanguage].buttons.accept}</button>
				</Col>
			</Row>
		</Container>
	);

}