import * as React from 'react';

/**
 * @var object language These are the fallback texts, if they could not be loaded from database.
**/
const language = {
	en: {
		titles: {
			contact: "Contact Data",
			signature: "Confirmation",
			supervision: "Supervision of the entered data",
			backend: "Administration",
			disclosure_main: "Non-Disclosure Agreement",
			compliance_main: "General Regulations",
			compliance_section_1: "",
			compliance_section_2: "<b>Please note the following safety instructions</b>",
			compliance_section_3: "",
			compliance_section_4: "<b>Important contacts in the company</b>",
			compliance_section_5: "<b>Behaviour in emergency situations</b>",
		},
		texts: {
			contact:
				"<b>Dear Visitor, Partner, Supplier and Employer, </b>"
				+ "<br /><br />" +
				"welcome at Ideenherd GmbH! "
				+ "<br />" +
				"Please fill your Contact Data:"
			,
			signature:
				"I hereby confirm the accuracy of my personal data. I have read, understood and accepted the confidentiality agreement and the rules of conduct of the Ideenherd GmbH."
			,
			supervision_section_1:
				"Please check the correctness of the entered data."
			,
			supervision_section_2:
				"Please enter the administration PIN:"
			,
			disclosure:
				"When processing Ideenherd GmbH contracts, I agree to observe strict confidentiality with respect to the information and materials made available to me and with respect to the knowledge of trade secrets and/or design work, new productions, constructions, and all associated business processes which I gain from them. This applies to the inventory used by Ideenherd GmbH, including hardware and software, their configuration, and developed or collected personal data. None of the material mentioned above may be copied or further processed under any circumstances."
				+ "<br /><br />" +
				"I further agree to observe strict confidentiality with respect to all information and materials accessible to me during the time I work for Ideenherd GmbH, such as models, images, CAD data and drawings from the areas mentioned above, and to ensure that third parties have no access to this information and material and no opportunity to evaluate them. After completion of assigned tasks, all information and materials made available during work are, after coordination with the principal, to be either returned or permanently deleted/destroyed. This includes intermediate results and work data generated during processing if such data allow the confidential information associated with the assignment to be inferred."
				+ "<br /><br />" +
				"I acknowledge that observance of the obligations cited above are binding for me. If these obligations are violated, legal actions will be initiated. Individual violations shall not be treated as one continuous violation. Claims for additional damages remain unaffected."
				+ "<br /><br />" +
				"The following remains unaffected: If the obligations to Ideenherd GmbH named above are not observed, the signee will be held liable for any damage claims arising therefrom. The legal provisions of Germany’s Unfair Competition Act (Gesetz gegen den unlauteren Wettbewerb, or UWG) apply (see §§ 17 ff. of the UWG and §§ 201 ff. of the German Criminal Code: imprisonment of up to three years)."
				+ "<br /><br />" +
				"I am aware that other legal regulations obligate me to protect secrets to the extent that those regulations are relevant within the scope of my activities. The following legal provisions are specifically applicable:"
				+ "<br />" +
				"• data secrecy for personal data according to the GDPR"
				+ "<br />" +
				"• telecommunications secrecy according to § 88 of the Germany Telecommunications Act (Telekommunikationsgesetz, or TKG)"
				+ "<br /><br />" +
				"The confidentiality obligation is enforced by sanctions for the period of work for/at Ideenherd GmbH and <u>for five years after that work ends</u>. It also extends to members of my family. Termination of business ties with Ideenherd GmbH during this period does not absolve the signee of his or her confidentiality obligation or indemnify him or her from sanctions resulting from breaches of the obligations named above."
				+ "<br /><br />" +
				"The signee will, by means of written agreements with his or her employees and vicarious agents, ensure that these individuals acknowledge the provisions in this agreement as binding for them or that they are obligated to fulfill them as part of the non-disclosure portion of their employment contract."
			,
			compliance_section_1: 'Your security is our joint responsibility.<br /><br />In order to ensure your security and our internal security, we ask you to familiarize yourself with the following regulations:<br />Please strictly adhere to the specifications your contact person. Visitors are encouraged to <u>always</u> stay in close proximity to it.',
			compliance_section_2: '['+
				'"Taking photos on our premises is prohibited.",'+
				'"Good hand hygiene is important to us, please disinfect your hands"'+
			']',
			compliance_section_3: '<h6><b>Working rules</b></h6>' +
				'In our company safety and environmental protection are very important.<br /><br />'+
				'You only have access to the work areas where you, as a visitor, are accompanied by your contact person. Company resources may only be used with our permission.<br /><br />'+
				'<h6><b>Basics:</b></h6>'+
				'You must always comply with all relevant legal regulations, accident prevention regulations, environmental regulations and the generally recognized rules of work and environmental protection. <br /><br />'+
				'Your contact person is responsible for the instruction.<br />'+
				'No one shall be permitted to work on our premises unless clearly and adequately instructed.<br /><br />'+
				'<h6><b>Regulations:</b></h6>'+
				'Files, photos, drawings, documents, copies, etc., may not be removed from the business premises of Ideenherd GmbH, duplicated or made accessible to unauthorized persons without the permission of the management.<br />'+
				'Carrying along or using photo or film equipment is not permitted or will be deactivated and taped off. Smoking is not permitted on our premises.<br /><br />'+
				'<h6><b>Security character:</b></h6>'+
				'The safety signs as well as the prohibition and information signs in our office must be observed.<br /><br />'+
				'<h6><b>Order and cleanliness:</b></h6>'+
				'Order and cleanliness must always be observed. After all work has been completed, the workplace must be left clean and tidy.'
			,
			compliance_section_4: '['+
				'"<tr><th>Function</th><th>Phone</th></tr>'+
				'<tr><td>Management</td><td>05361-89393940</td></tr>'+
				'<tr><td>Information Security Officer</td><td>05361-89393930</td></tr>'+
				'<tr><td>Data protection officer</td><td>0160-92404996</td></tr>'+
				'<tr><td>First aiders</td><td>05361-89393935</td></tr>",'+
				'"<b>Contact</b><br /><br />Ideenherd GmbH<br />Zu dem Balken 25<br />38448 Wolfsburg<br /><br />Phone: +49 5361 893939-0<br /><br />E-Mail: info@ideenherd.de<br />Web: www.ideenherd.de"'+
			']',
			compliance_section_5: '['+
				'"Keep calm, alert the appropriate authorities:<br /><br />",'+
				'"In an emergency:<br />The emergency call can be made from any phone.<br />Police: 110<br />Fire department: 112<br />Emergency service: 112",'+
				'"Leave the danger area via the marked rescue routes."'+
			']',
		},
		language: {
			name: "Deutsch",
		},
		form: {
			forename: "First name",
			surname: "Last name",
			street: "Street, House number/Post box",
			postcode: "Postal code",
			city: "City",
			company: "Company",
			appointment_location: "Local",
			local_pre_fill: "Wolfsburg",
			appointment_date: "Date",
			email: "E-mail",
			phone: "Telephone",
			signature: "Signature:",
			visit_reason: "Reason for the visit",
			appointment_s_time: "from",
			appointment_e_time: "until",
		},
		error: {
			unknown: "Something went wrong!",
			credentials: "Please check your credentials!",
		},
		buttons: {
			accept: "Accept and forward",
			abort: "Abort",
			clear: "Clear",
			next: "Next",
			save: "Save",
			newVisit: "Visitor registration",
			newDisclosure: "Non-Disclosure agreement",
			admin: "Administration",
			login: "Login",
			logout: "Logout",
			users: "Visitors",
			admins: "Administrators",
			changePin: "Create new PIN",
			pins: "Pins",
		},
		footer: {
			col1: "<b>Address</b><br />Ideenherd GmbH<br />Zu dem Balken 25<br />D-38448 Wolfsburg",
			col2: "<b>Contact</b><br />Phone + 49 (0) 53 61 / 893939-0<br />info@ideenherd.de<br />www.ideenherd.de",
			col3: "<b>Brunswick District Court</b><br />HRB 100 157<br />CEO: Jörg Lehmann",
		}
	},
	de: {
		titles: {
			contact: "Kontaktdaten",
			signature: "Bestätigung",
			supervision: "Überprüfung der angegebenen Daten",
			backend: "Administration",
			disclosure_main: "Vertraulichkeitsvereinbarung",
			compliance_main: "Verhaltensregeln",
			compliance_section_1: "",
			compliance_section_2: "<b>Beachten Sie bitte folgende Sicherheitshinweise</b>",
			compliance_section_3: "",
			compliance_section_4: "<b>Wichtige Ansprechpartner im Unternehmen</b>",
			compliance_section_5: "<b>Verhalten in Notsituationen</b>",
		},
		texts: {
			contact:
				"<b>Liebe Besucher, Partner, Lieferanten und Mitarbeiter,</b>"
				+ "<br /><br />" +
				"herzlich willkommen bei der Ideenherd GmbH! "
				+ "<br />" +
				"Bitte geben Sie Ihre Kontaktdaten ein:"
			,
			signature:
				"Hiermit bestätige ich die Richtigkeit meiner persönlichen Daten. Ich habe die Vertraulichkeitsvereinbarung sowie die Verhaltensregeln der Ideenherd GmbH gelesen, verstanden und akzeptiert."
			,
			supervision_section_1:
				"Bitte kontrollieren Sie die Richtigkeit der eingegebenen Daten."
			,
			supervision_section_2:
				"Bitte geben Sie die Administratoren PIN ein:"
			,
			disclosure:
				"Ich verpflichte mich, strengstens Stillschweigen über alle mir bekannt gewordenen und/oder mir zur Verfügung gestellten Informationen und Materialien der Ideenherd GmbH und die daraus gewonnenen Kenntnisse über Geschäftsgeheimnisse und/oder Kenntnisse über sämtliche Designarbeiten, Neuanfertigungen, Konstruktionen und alle damit in Verbindung stehenden Geschäftsvorgänge zu bewahren. Dies gilt auch für das in der Ideenherd GmbH eingesetzte Inventar einschließlich Hard- und Software, deren Konfiguration und entwickelter oder erhobener personenbezogener Daten. Für das gesamte o.g. Material besteht strengstes Kopier- und Weiterverarbeitungsverbot."
				+ "<br /><br />" +
				"Des Weiteren verpflichte ich mich, alle mir bereitgestellten Informationen und Materialien, bspw. Modelle, Bildmaterial, CAD-Daten und Zeichnungen von o.g. Bereichen, die mir im Zeitraum meines Besuches bei der Ideenherd GmbH zugänglich sind, streng vertraulich zu behandeln und zu gewährleisten, dass diese Informationen und Materialien Dritten zu keinem Zeitpunkt zugänglich sind und von diesen nicht ausgewertet werden können. Nach Beendigung des Besuches sind alle in diesem Rahmen zur Verfügung gestellten Informationen und Materialien der Ideenherd GmbH nach Absprache entweder zurückzugeben oder unwiederbringlich zu löschen/vernichten. Dieses schließt Zwischenergebnisse und bei der Bearbeitung anfallende Arbeitsdaten mit ein, sofern durch diese ein Rückschluss auf die vertraulichen Inhalte der Beauftragung möglich ist."
				+ "<br /><br />" +
				"Ich erkenne die Einhaltung der vorgenannten Verpflichtungen als für mich verbindlich an. Für den Fall der Zuwiderhandlung werden rechtliche Schritte eingeleitet. Die Berufung auf Fortsetzungszusammenhang wird dabei ausgeschlossen. Die Geltendmachung eines darüber hinaus bestehenden Schadensersatzanspruchs bleibt uneingeschränkt bestehen."
				+ "<br /><br />" +
				"Unberührt hiervon bleibt: Entsteht durch Missachtung der o.g. Verpflichtungen gegenüber der Ideenherd GmbH ein Schaden, so wird der Unterzeichnende für einen entstehenden Schadensersatzanspruch regresspflichtig gemacht. Auf die gesetzlichen Bestimmungen im Gesetz gegen unlauteren Wettbewerb bzw. des Strafgesetzbuches wird hingewiesen (§§ 17 ff. UWG, 201 ff StGB, Freiheitsstrafe bis zu drei Jahren)."
				+ "<br /><br />" +
				"Mir ist bewusst, dass mich weitere gesetzliche Vorschriften zur Wahrung von Geheimnissen verpflichten, sofern diese im Rahmen meiner Tätigkeiten relevant sind. Hierbei sind insbesondere folgende gesetzliche Bestimmungen zu beachten:"
				+ "<br />" +
				"• das Datengeheimnis für personenbezogene Daten gem. DSGVO"
				+ "<br />" +
				"• das Fernmeldegeheimnis gem. § 88 TKG"
				+ "<br /><br />" +
				"Die Geheimhaltungsverpflichtung mit Sanktionsfolgen erstreckt sich über den Zeitraum des Besuchs bei der Ideenherd GmbH hinaus bis einschließlich fünf Jahre nach dessen Beendigung. Sie besteht ferner auch gegenüber Angehörigen meiner Familie. Auch eine Beendigung der Geschäftsverbindungen mit der Ideenherd GmbH während dieses Zeitraumes entbindet den Unterzeichnenden nicht von der Geheimhaltungsverpflichtung einschließlich der Sanktionsfolgen bei Verstößen gegen die o.g. Verpflichtungen."
				+ "<br /><br />" +
				"Der Unterzeichnende wird durch geeignete schriftliche Vereinbarung mit seinen Mitarbeitern, Erfüllungs-/Verrichtungshilfen sicherstellen, dass diese die in dieser Vereinbarung festgelegten Bestimmungen als für sich verbindlich anerkennen oder entsprechend durch ihren Dienst-/Arbeitsvertrag zur Geheimhaltung verpflichtet sind."
			,
			compliance_section_1: 'Ihre Sicherheit ist unsere gemeinsame Verantwortung.<br /><br />Um Ihre Sicherheit und unsere betriebsinterne Sicherheit zu gewährleisten, bitten wir Sie, sich mit den folgenden Regelungen vertraut zu machen:<br />Bitte halten Sie sich strikt an die Vorgaben Ihres Ansprechpartners. Besucher sind angehalten, sich <u>immer</u> in unmittelbarer Nähe zu diesem aufzuhalten.',
			compliance_section_2: '['+
				'"Das Fotografieren in unseren Räumlichkeiten ist untersagt.",'+
				'"Eine gute Handhygiene ist uns wichtig, desinfizieren Sie bitte Ihre Hände"'+
			']',
			compliance_section_3: '<h6><b>Betriebsordnung</b></h6>' +
				'In unserem Unternehmen haben Sicherheit und Umweltschutz einen hohen Stellenwert.<br /><br />'+
				'Sie haben nur zu den Arbeitsbereichen Zutritt, in denen Sie als Besucher durch Ihren Ansprechpartner begleitet werden. Betriebsmittel unseres Unternehmens dürfen nur mit unserer Erlaubnis benutzt werden.<br /><br />'+
				'<h6><b>Grundsätzliches:</b></h6>'+
				'Alle einschlägigen Rechtsvorschriften, Unfallverhütungs-vorschriften, Umweltvorschriften sowie die allgemein anerkannten Regeln des Arbeits- und Umweltschutzes müssen von Ihnen stets eingehalten werden. <br /><br />'+
				'Für die Unterweisung ist Ihre Kontaktperson verantwortlich.<br />'+
				'Niemand darf seine Tätigkeit in unseren Räumlichkeiten aufnehmen, der nicht eindeutig und angemessen darin unterwiesen ist.<br /><br />'+
				'<h6><b>Vorschriften:</b></h6>'+
				'Akten, Fotos, Zeichnungen, Schriftstücke, Kopien usw., dürfen ohne Erlaubnis der Geschäftsleitung nicht aus den Geschäfts-räumen der Ideenherd GmbH entnommen, vervielfältigt oder Unbefugten zugänglich gemacht werden.<br />'+
				'Das Mitführen oder Benutzen von Foto- oder Filmgeräten ist nicht gestattet bzw. wird deaktiviert und abgeklebt. In unseren Räumlichkeiten besteht Rauchverbot.<br /><br />'+
				'<h6><b>Sicherheitszeichen:</b></h6>'+
				'Die Sicherheitszeichen sowie die Verbots- und Hinweiszeichen in unserem Büro sind zwingend zu beachten.<br /><br />'+
				'<h6><b>Ordnung und Sauberkeit:</b></h6>'+
				'Auf Ordnung und Sauberkeit ist stets zu achten. Nach Erledigung sämtlicher Arbeiten ist der Arbeitsplatz ordentlich und sauber zu verlassen.'
			,
			compliance_section_4: '['+
				'"<tr><th>Funktion</th><th>Telefon</th></tr>'+
				'<tr><td>Geschäftsführung</td><td>05361-89393940</td></tr>'+
				'<tr><td>Informationssicherheitsbeauftragter</td><td>05361-89393930</td></tr>'+
				'<tr><td>Datenschutzbeauftragter</td><td>0160-92404996</td></tr>'+
				'<tr><td>Ersthelfer</td><td>05361-89393935</td></tr>",'+
				'"<b>Kontakt</b><br /><br />Ideenherd GmbH<br />Zu dem Balken 25<br />38448 Wolfsburg<br /><br />Telefon: +49 5361 893939-0<br /><br />E-Mail: info@ideenherd.de<br />Web: www.ideenherd.de"'+
			']',
			compliance_section_5: '['+
				'"Bewahren Sie Ruhe, alarmieren Sie die entsprechenden Stellen:<br /><br />",'+
				'"Im Notfall:<br />Der Notruf kann von jedem Telefon abgesetzt werden.<br />Polizei: 110<br />Feuerwehr: 112<br />Rettungsdienst: 112",'+
				'"Verlassen Sie den Gefahrenbereich über die markierten Rettungswege."'+
			']',
		},
		language: {
			name: "English",
		},
		form: {
			forename: "Vorname",
			surname: "Name",
			street: "Straße, Hausnr.",
			postcode: "PLZ/Postfach",
			city: "Stadt",
			company: "Firma",
			appointment_location: "Ort",
			local_pre_fill: "Wolfsburg",
			appointment_date: "Datum",
			email: "E-Mail",
			phone: "Telefon",
			signature: "Unterschrift:",
			visit_reason: "Grund des Besuchs",
			appointment_s_time: "von",
			appointment_e_time: "bis",
		},
		error: {
			unknown: "Es ist ein Fehler aufgetreten!",
			credentials: "Bitte überprüfen Sie Ihre Zugangsdaten!",
		},
		buttons: {
			accept: "Einverstanden und Weiter",
			abort: "Abbrechen",
			clear: "Löschen",
			next: "Weiter",
			save: "Speichern",
			newVisit: "Besucherprotokoll",
			newDisclosure: "Vertraulichkeitsvereinbarung",
			admin: "Administration",
			login: "Login",
			logout: "Logout",
			users: "Besucher",
			admins: "Administratoren",
			changePin: "Neue PIN erstellen",
			pins: "PINs",
		},
		footer: {
			col1: "<b>Anschrift</b><br />Ideenherd GmbH<br />Zu dem Balken 25<br />D-38448 Wolfsburg",
			col2: "<b>Kontakt</b><br />Telefon + 49 (0) 53 61 / 893939-0<br />info@ideenherd.de<br />www.ideenherd.de",
			col3: "<b>Amtsgericht Braunschweig</b><br />HRB 100 157<br />Geschäftsführer: Jörg Lehmann",
		}
	}
}

const tableConfigLanguage = {
	de: {
		pagination: {
			labelDisplayedRows: '{from}-{to} von {count}',
			labelRowsSelect: "Zeilen",
			firstTooltip: "erste Seite",
			previousTooltip: "vorherige Seite",
			nextTooltip: "nächste Seite",
			lastTooltip: "letzte Seite"
		},
		toolbar: {
			searchTooltip: "suchen",
			searchPlaceholder: "suchen",
			exportTitle: "exportieren",
			exportCSVName: "als CSV exportieren",
			exportPDFName: "als PDF exportieren"
		},
		body: {
			emptyDataSourceMessage: 'No records!',
			editRow: {
				deleteText: "Möchten Sie diese Zeile wirklich löschen?",
				cancelTooltip: "Abbrechen",
				saveTooltip: "Speichern",
			},
			addTooltip: "Hinzufügen",
			deleteTooltip: "Löschen",
			editTooltip: "Editieren"
		}
	},
	en: {
		pagination: {
			labelDisplayedRows: '{from}-{to} from {count}',
			labelRowsSelect: "Rows",
			firstTooltip: "First Page",
			previousTooltip: "Previous Page",
			nextTooltip: "Next Page",
			lastTooltip: "Last Page"
		},
		toolbar: {
			searchTooltip: "Search",
			searchPlaceholder: "Search",
			exportTitle: "Export",
			exportCSVName: "Export to CSV",
			exportPDFName: "Export to PDF"
		},
		body: {
			emptyDataSourceMessage: 'Keine Daten!',
			editRow: {
				deleteText: "Are you sure you want to delete this row?",
				cancelTooltip: "Cancel",
				saveTooltip: "Save",
			},
			addTooltip: "Add",
			deleteTooltip: "Delete",
			editTooltip: "Edit"
		}
	}
}


const table = {
	users: {
		config: {
			default: [
				{hidden: true, export: false, editable: 'never' },
				{hidden: false, export: true},
				{hidden: false, export: true},
				{hidden: false, export: true},
				{hidden: false, export: true},
				{hidden: false, export: true},
				{hidden: true, export: true},
				{hidden: true, export: true},
				{hidden: true, export: true},
				{hidden: true, export: true},
				{hidden: false, export: true},
				{hidden: false, export: true},
				{hidden: true, export: false},
				{hidden: true, export: true},
				{hidden: true, export: false},
			],
			all: [
				{hidden: true, export: false, editable: 'never' },
				{hidden: false, export: true},
				{hidden: false, export: true},
				{hidden: false, export: true},
				{hidden: false, export: true},
				{hidden: false, export: true},
				{hidden: false, export: true},
				{hidden: false, export: true},
				{hidden: false, export: true},
				{hidden: false, export: true},
				{hidden: false, export: true},
				{hidden: false, export: true},
				{hidden: true, export: false},
				{hidden: false, export: true},
				{hidden: false, export: false, render: (rowData) => <img src={rowData.signature} style={{maxHeight:"15px"}} alt="" />},
			]
		},
		lng: {
			de: [
				{title: "Id", field: "id"},
				{title: "Datum", field: "appointment_date"},
				{title: "von", field: "appointment_s_time"},
				{title: "bis", field: "appointment_e_time"},
				{title: "Vorname", field: "forename"},
				{title: "Nachname", field: "surname"},
				{title: "Straße", field: "street"},
				{title: "PLZ", field: "postcode"},
				{title: "Stadt", field: "city"},
				{title: "Firma", field: "company"},
				{title: "E-Mail", field: "email"},
				{title: "Telefon", field: "phone"},
				{title: "Ort", field: "appointment_location"},
				{title: "Grund des Besuchs", field: "visit_reason"},
				{title: "", field: "signature"},
			],
			en: [
				{title: "Id", field: "id"},
				{title: "Date", field: "appointment_date"},
				{title: "From", field: "appointment_s_time"},
				{title: "Until", field: "appointment_e_time"},
				{title: "Forename", field: "forename"},
				{title: "Surname", field: "surname"},
				{title: "Street", field: "street"},
				{title: "Postal code", field: "postcode"},
				{title: "City", field: "city"},
				{title: "Company", field: "company"},
				{title: "E-mail", field: "email"},
				{title: "Phone", field: "phone"},
				{title: "Location", field: "appointment_location"},
				{title: "Visit Reason", field: "visit_reason"},
				{title: "", field: "signature"},
			]
		},
		buttons: {
			de: {
				default: "Spalten einblenden",
				all: "Spalten ausblenden"
			},
			en: {
				default: "Show all Columns",
				all: "Show less Columns"
			}
		}
	},
	admins: {
		config: {
			default: [
				{hidden: false, export: true, editable: 'never' },
				{hidden: false, export: true},
				{hidden: false, export: true},
				{hidden: false, export: false},
				{hidden: false, export: false,
					render: rowData => <span>*****</span>
				},
				{hidden: false, export: true, render: (rowData) => {

					if (rowData.active === "1") return <svg width="2em" height="2em" viewBox="0 0 16 16" className="bi bi-check" fill="currentColor" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.236.236 0 0 1 .02-.022z"/></svg>

				}},
			]
		},
		lng: {
			de: [
				{title: "Id", field: "id"},
				{title: "Vorname", field: "forename"},
				{title: "Nachname", field: "surname"},
				{title: "Username", field: "username"},
				{title: "Passwort", field: "password",
					editComponent: props => {
						return (<div className="MuiFormControl-root MuiTextField-root MuiFormControl-fullWidth"><div className="MuiInputBase-root MuiInput-root MuiInput-underline MuiInputBase-fullWidth MuiInput-fullWidth MuiInputBase-formControl MuiInput-formControl" style={{fontSize: "13px"}} ><input aria-invalid="false" placeholder="Passwort" type="password" aria-label="Passwort" className="MuiInputBase-input MuiInput-input"  onChange={e => props.onChange(e.target.value)} value={props.value} /></div></div>)
					}
				},
				{title: "Aktiv", field: "active", lookup: { 0: 'Nein', 1: 'Ja' }},
			],
			en: [
				{title: "Id", field: "id"},
				{title: "Forename", field: "forename"},
				{title: "Surname", field: "surname"},
				{title: "Username", field: "username"},
				{title: "Password", field: "password",
					editComponent: props => {
						return (<div className="MuiFormControl-root MuiTextField-root MuiFormControl-fullWidth"><div className="MuiInputBase-root MuiInput-root MuiInput-underline MuiInputBase-fullWidth MuiInput-fullWidth MuiInputBase-formControl MuiInput-formControl" style={{fontSize: "13px"}} ><input aria-invalid="false" placeholder="Password" type="password" aria-label="Password" className="MuiInputBase-input MuiInput-input"  onChange={e => props.onChange(e.target.value)} value={props.value} /></div></div>)
					}
				},
				{title: "Active", field: "active", lookup: { 0: 'No', 1: 'Yes' }},
			]
		}
	},
	pins: {
		config: {
			default: [
				{hidden: true, export: false, editable: 'never'},
				{hidden: false, export: false, editable: 'never'},
				{hidden: false, export: false, editable: 'never'},
				{hidden: false, export: false, render: (rowData) => {

					if (rowData.active === "1") return <svg width="2em" height="2em" viewBox="0 0 16 16" className="bi bi-check" fill="currentColor" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.236.236 0 0 1 .02-.022z"/></svg>

				}},
			]
		},
		lng: {
			de: [
				{title: "Id", field: "id"},
				{title: "Erstellungsdatum", field: "creation_date"},
				{title: "PIN", field: "value"},
				{title: "Aktiv", field: "active", lookup: { 0: 'Nein', 1: 'Ja' }},
			],
			en: [
				{title: "Id", field: "id"},
				{title: "Creation date", field: "creation_date"},
				{title: "PIN", field: "value"},
				{title: "Active", field: "active", lookup: { 0: 'No', 1: 'Yes' }},
			]
		},
		texts: {
			de: {
				currentPin: "Die aktuelle PIN lautet"
			},
			en: {
				currentPin: "The current PIN is"
			},
		}
	}
}



const ConfigContext = React.createContext();

export default ConfigContext;

export {language, tableConfigLanguage, table};