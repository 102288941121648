/**
 * @var bool SECURE
**/
const secure = (window.location.protocol === "https");


const GlobalVariables = { SECURE: secure };

// Make the Object immutable to changes.
Object.freeze(GlobalVariables);


export default GlobalVariables;