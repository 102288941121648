import React, { Component } from "react";
import SignatureCanvas from 'react-signature-canvas';


class SignaturePad extends Component {

    state = {
        trimmedDataURL: ""
    }

    sigPadRef = React.createRef();
    divRef = React.createRef();

    clear = (e) => {
        if (e) e.preventDefault();
        this.setState({ trimmedDataURL: "" })
        this.sigPadRef.current.clear()
        this.props.getsignature("")
    }

    trim = () => {
        this.setState({ trimmedDataURL: this.sigPadRef.current.getTrimmedCanvas()
        .toDataURL('image/png') }, () => {
            //console.log(this.state.trimmedDataURL)
            this.props.getsignature(this.state.trimmedDataURL)
        })
    }

    componentDidMount() {
      this.sigPadRef.current._canvas.width = this.divRef.current.clientWidth
    }

    componentDidUpdate(prevProps, prevState) {

      if (prevProps && prevProps !== this.props && this.state.trimmedDataURL && this.props.clear) {
          this.clear();
      }

    }


    render() {
      return (
        <div className="w-100" ref={this.divRef}>
          <div className="w-100">
            <SignatureCanvas penColor={this.props.color} canvasProps={{width: this.props.width, height: this.props.height, className: 'sigCanvas ' + this.props.error}} ref={this.sigPadRef} onEnd={this.trim} />
          </div>
          <div className="w-100 text-right">
            <button type="button" className="btn btn-sm btn-warning" onClick={this.clear}>{this.props.btClear}</button>
          </div>
        </div>
      );
    }

}

export default SignaturePad;