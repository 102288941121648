import React, {useState, useEffect, useContext} from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Cookies from 'js-cookie';

import Bugsnag from '@bugsnag/js'

import AuthContext from '../AuthContext';

import Loader from '../Components/Loader';
import Table from '../Components/Table';


import GlobalVariables from '../GlobalVariables';
import ConfigContext, {table} from '../ConfigContext';


export default function Page(props) {

	const [loader, setLoader] = useState(true);// The loader element on initial page load.
	// Not just a variable? Used to determine wether backend login form / table is shown, but has also influence on app performance.
	const [splash, setSplash] = useState(true);


	// --- Form --- //
	const [loginValues, setLoginValues] = useState({});
	// --- Form --- //


	// --- Table --- //
	const [tableData, setTableData] = useState([]);
	const [tableConfig, setTableConfig] = useState("default");
	const [currentTabView, setCurrentTabView] = useState("users");
	// --- Table --- //


	// --- Language - Texts --- //
	const {texts, setActiveView, pin, getPin} = props;
	const {currentLanguage, API_URL} = useContext(ConfigContext);
	const language = texts;
	// --- Language - Texts --- //

	const defaultMessage = { show: false, text: language[currentLanguage].error.credentials }
	const [message, setMessage] = useState(defaultMessage);



	useEffect(() => {
		if (message.show) {
			setTimeout(() => {
				setMessage({show: false});
			}, 3000);
		}
	}, [message.show]);



	const onChange = (e) => {
		setLoginValues({...loginValues, ...{[e.target.name]: e.target.value}});
	}

	const onKeyUp = (e) => {
		if (e.keyCode === 13) authContext.signIn(loginValues);
	}


	const [stateLogin, dispatch] = React.useReducer(
		// Reducer function
		(prevState, action) => {
			switch (action.type) {
				case 'RESTORE_TOKEN':
				case 'SIGN_IN':
					return {
						userToken: action.token
					};
				case 'SIGN_OUT':
				default:
					return {
						userToken: null
					};
			}
		},
		// State
		{ userToken: null }
	);



	const authContext = {
		stateLogin,
		signIn: async data => {

			if (!data.username || !data.password) {
				setMessage({show: true, text: defaultMessage.text});
				return false;
			}

			setLoader(true);

			fetch(API_URL+'/login', {
				method: 'POST',
				body: JSON.stringify({
					login: data.username, password: data.password
				})
			})
			.then((response) => Promise.all([response.status, response.json()]))
			.then(([status, result]) => {

				if (status === 200 && result && result.token) {

					try {
						Cookies.set('userToken', result.token, {
							expires: new Date('Thu, 01-Jan-1970 00:00:01 GMT'),
							path: '/',
							domain: '',
							secure: GlobalVariables.SECURE,
							httponly: false,
							samesite: 'strict'
						});
					} catch (reason) {
						reason.message = "Could not set the token.\n" + reason.message;
						Bugsnag.notify(reason, function (event) {
							event.severity = 'warning';
						});


						// Do default action for failed try.
					}


					dispatch({ type: 'SIGN_IN', token: result.token });

				} else {
					setMessage({show: true, text: defaultMessage.text});
				}

			})
			.catch((reason) => {
				reason = "" + reason;
				Bugsnag.notify(new Error(reason), function (event) {
					event.severity = 'warning';
				});


				// Do default state action for failed fetch.
				setMessage({show: true, text: defaultMessage.text});
			})
			.finally(() => {
				setLoader(false);

				console.log("Sigin end");
			});

		},
		signOut: () => {

			const logout = new Promise((resolve, reject) => {
				const logout = async () => {
					try {
						await Cookies.remove('userToken', {
							expires: 0,
							path: '/',
							domain: '',
							secure: GlobalVariables.SECURE,
							httponly: false,
							samesite: 'strict'
						});
						return true;
					} catch (reason) {
						reason.message = "" + reason.message;
						Bugsnag.notify(reason, function (event) {
							event.severity = 'warning';
						});


						// Do default action for failed try.
						return false;
					}
				}

				if (logout()) {
					resolve(true);
				} else {
					reject(false);
				}
			});


			logout
			.then((res) => {
				dispatch({ type: 'SIGN_OUT' });
			})
			.catch((reason) => {
				reason = "" + reason;
				Bugsnag.notify(new Error(reason), function (event) {
					event.severity = 'warning';
				});


				// Do default state action for failed fetch.
				setMessage({show: true, text: language[currentLanguage].error.unknown});
			});

		}
	}



	useEffect(() => {


		// Fetch the token from storage then navigate to our appropriate place
		const getToken = async () => {
			let userToken;

			try {
				userToken = await Cookies.get('userToken');
			} catch (reason) {
				// Restoring token failed
				reason.message = "Could not restore the token.\n" + reason.message;
				Bugsnag.notify(reason, function (event) {
					event.severity = 'warning';
				});


				// Do default action for failed try.
				userToken = null;
			}

			return [userToken];
		};


		getToken()
		.then((res) => {

			if (res[0]) {
				// Do something if login token is set.


				fetch(API_URL+'/gettoken', {
					method: 'POST',
					headers: {
						'Authorization': "Bearer "+res[0],
						'Accept': 'application/json',
						'Content-Type': 'application/json',
					}
				})
				.then((response) => {
					return Promise.all([response.status, response.json()]);
				})
				.then(([status, result]) => {

					if (status === 200 && result && result.token) {

						try {
							Cookies.set('userToken', result.token, {
								expires: new Date('Thu, 01-Jan-1970 00:00:01 GMT'),
								path: '/',
								domain: '',
								secure: GlobalVariables.SECURE,
								httponly: false,
								samesite: 'strict'
							});
						} catch (reason) {
							reason.message = "Could not set the token.\n" + reason.message;
							Bugsnag.notify(reason, function (event) {
								event.severity = 'warning';
							});


							// Do default action for failed try.
						}


						dispatch({ type: 'RESTORE_TOKEN', token: result.token });

					} else {
						dispatch({ type: 'SIGN_OUT' });
						Cookies.remove('userToken', {
							expires: 0,
							path: '/',
							domain: '',
							secure: GlobalVariables.SECURE,
							httponly: false,
							samesite: 'strict'
						});
					}

				})
				.catch((reason) => {
					reason = "" + reason;
					Bugsnag.notify(new Error(reason), function (event) {
						event.severity = 'warning';
					});


					// Do default state action for failed fetch.
					dispatch({ type: 'SIGN_OUT' });
					Cookies.remove('userToken', {
						expires: 0,
						path: '/',
						domain: '',
						secure: GlobalVariables.SECURE,
						httponly: false,
						samesite: 'strict'
					});
				})
				.finally(() => {
					setLoader(false);
					setSplash(false);

					console.log("Login end");
				});


			} else {
				// Do something if login token is missing.


				dispatch({ type: 'SIGN_OUT' });
				Cookies.remove('userToken', {
					expires: 0,
					path: '/',
					domain: '',
					secure: GlobalVariables.SECURE,
					httponly: false,
					samesite: 'strict'
				});

				setLoader(false);
				setSplash(false);


			}

		})
		.catch((reason) => {
			reason = "Error getting token.\n" + reason;
			Bugsnag.notify(new Error(reason), function (event) {
				event.severity = 'warning';
			});


			// Do default state action for failed fetch.
			dispatch({ type: 'SIGN_OUT' });
			Cookies.remove('userToken', {
				expires: 0,
				path: '/',
				domain: '',
				secure: GlobalVariables.SECURE,
				httponly: false,
				samesite: 'strict'
			});

			setLoader(false);
			setSplash(false);
		});


	}, [API_URL]);




	useEffect(() => {


		if (authContext.stateLogin.userToken) {

			setLoader(true);
			setTableData([]);

			fetch(API_URL+'/get/'+currentTabView, {
				method: 'POST',
				headers: {
					'Authorization': "Bearer "+authContext.stateLogin.userToken,
					'Accept': 'application/json',
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					language: currentLanguage
				})
			})
			.then((response) => {
				return Promise.all([response.status, response.json()]);
			})
			.then(([status, result]) => {

				if (status === 200 && result) {

					setTableData(result);

				} else {
					console.log("Data fetch error.");
				}

			})
			.catch((reason) => {
				reason = "Data fetch error.\n" + reason;
				Bugsnag.notify(new Error(reason), function (event) {
					event.severity = 'warning';
				});


				// Do default state action for failed fetch.
				dispatch({ type: 'SIGN_OUT' });
				Cookies.remove('userToken', {
					expires: 0,
					path: '/',
					domain: '',
					secure: GlobalVariables.SECURE,
					httponly: false,
					samesite: 'strict'
				});
			})
			.finally(() => {
				setLoader(false);
				console.log("Data fetch end.");
			});
		}


	}, [API_URL, authContext.stateLogin.userToken, currentLanguage, tableConfig, currentTabView]);



	const changeTableConfig = () => {
		if (tableConfig === "all") setTableConfig("default");
		else setTableConfig("all");
	}



	const handleTablechanges = (type, newData, oldData, allData, resolve, reject) => {

		switch (type) {
			case "update":
			case "insert":

				if (!newData.forename || !newData.surname || !newData.username || !newData.password || !newData.active) {
					reject()
					return false;
				}

				var password = newData.password;

				if (type === "update" && newData.password === oldData.password) password = ""

				setLoader(true)

				fetch(API_URL+'/'+type+'/'+currentTabView+(type === "update"?'/'+oldData.id:''), {
					method: 'POST',
					headers: {
						'Authorization': "Bearer "+authContext.stateLogin.userToken,
						'Accept': 'application/json',
						'Content-Type': 'application/json',
					},
					body: JSON.stringify({
						forename: newData.forename,
						surname: newData.surname,
						username: newData.username,
						password: password,
						active: newData.active,
					})
				})
				.then((response) => {
					return Promise.all([response.status, response.json()]);
				})
				.then(([status, result]) => {

					if (status === 200 && result) {

						if (type === "insert") {
							newData.id = result.saved;
							setTableData([...allData, newData]);
						} else {
							let dataUpdate = [...allData];
							const index = oldData.tableData.id;
							dataUpdate[index] = newData;
							setTableData([...dataUpdate]);
						}
						resolve();

					} else {
						reject();
						setMessage({show: true, text: language[currentLanguage].error.unknown});
					}

				})
				.catch((reason) => {
					reason = "" + reason;
					Bugsnag.notify(new Error(reason), function (event) {
						event.severity = 'warning';
					});


					// Do default state action for failed fetch.
					reject();
					setMessage({show: true, text: language[currentLanguage].error.unknown});
				})
				.finally(() => {
					setLoader(false);
				});


				break;

			case "delete":

				setLoader(true)

				fetch(API_URL+'/delete/'+currentTabView+'/'+oldData.id, {
					method: 'POST',
					headers: {
						'Authorization': "Bearer "+authContext.stateLogin.userToken,
						'Accept': 'application/json',
						'Content-Type': 'application/json',
					}
				})
				.then((response) => {
					return Promise.all([response.status, response.json()]);
				})
				.then(([status, result]) => {

					if (status === 200 && result) {

						let dataDelete = [...allData];
						const index = oldData.tableData.id;
						dataDelete.splice(index, 1);
						setTableData([...dataDelete]);
						resolve();

					} else {
						reject();
						setMessage({show: true, text: language[currentLanguage].error.unknown});
					}

				})
				.catch((reason) => {
					reason = "" + reason;
					Bugsnag.notify(new Error(reason), function (event) {
						event.severity = 'warning';
					});


					// Do default state action for failed fetch.
					reject();
					setMessage({show: true, text: language[currentLanguage].error.unknown});
				})
				.finally(() => {
					setLoader(false);
				});

				break;

			default:

				break;
		}

	}



	// --- PIN --- //
	const handlePINChange = () => {

		fetch(API_URL+'/create/pin/', {
			method: 'POST',
			headers: {
				'Authorization': "Bearer "+authContext.stateLogin.userToken,
				'Accept': 'application/json',
				'Content-Type': 'application/json',
			}
		})
		.then((response) => Promise.all([response.status, response.json()]))
		.then(([status, res]) => {

			if (status === 200 && res) {

				if (typeof res.saved !== "undefined" && res.saved) {
					getPin(API_URL);
				}

			}

		})
		.catch((reason) => {
			reason = "Error occured creating new pin.\n" + reason;
			Bugsnag.notify(new Error(reason), function (event) {
				event.severity = 'warning';
			});


			// Do default state action for failed fetch.
		})
		.finally(() => {
			setLoader(false);
			setSplash(false);
		})

	}
	// --- PIN --- //



	return (
		<AuthContext.Provider value={authContext}>

		<Loader active={loader} />

		<Container className="mt-4 flex-grow-1">
		<Row>
			<Col className="text-left d-flex">
				<div><button type="button" className="btn btn-secondary btn-sm" onClick={() => setActiveView("home")}>&lt;</button></div>
				<div className="ml-3 w-100"><h4>{language[currentLanguage].titles.backend}</h4></div>
			</Col>
			{ authContext.stateLogin.userToken &&
				<Col className="text-right d-flex justify-content-end">
					<div><button type="button" className="btn btn-outline-secondary btn-sm" onClick={() => authContext.signOut()}>{language[currentLanguage].buttons.logout}</button></div>
				</Col>
			}
		</Row>
		</Container>

		<Container className="flex-grow-1">
		<Row>
			{!splash && ((!authContext.stateLogin.userToken)
				?
				<Col>
					<div style={{maxWidth:"300px", margin:"0 auto"}} className="text-right">
						<div className="form-group">
							<input type="text" className="form-control" name="username" placeholder="Username" autoComplete="off" onChange={onChange} onKeyUp={onKeyUp} />
						</div>

						<div className="form-group">
							<input type="password" className="form-control" name="password" placeholder="Passwort" autoComplete="off" onChange={onChange} onKeyUp={onKeyUp} />
						</div>

						<button type="button" className="btn btn-primary" onClick={() => authContext.signIn(loginValues)}>
							{language[currentLanguage].buttons.login}
						</button>
					</div>
				</Col>
				:
				<Col className="pt-3">
					<div className="d-flex justify-content-between flex-wrap-reverse">

					<ul className="nav nav-tabs">
						<li className="nav-item">
							<button className={"nav-link " + (currentTabView === "users" ? "active" : "")} onClick={() => setCurrentTabView("users")}>
								{language[currentLanguage].buttons.users}
							</button>
						</li>
						<li className="nav-item">
							<button className={"nav-link " + (currentTabView === "admins" ? "active" : "")} onClick={() => {setTableConfig("default"); setCurrentTabView("admins"); }}>
								{language[currentLanguage].buttons.admins}
							</button>
						</li>
						<li className="nav-item">
							<button className={"nav-link " + (currentTabView === "pins" ? "active" : "")} onClick={() => {setTableConfig("default"); setCurrentTabView("pins");} }>
								{language[currentLanguage].buttons.pins}
							</button>
						</li>
					</ul>

					{ (currentTabView === "users") &&
						<div className="d-flex justify-content-end mb-2">
							<button type="button" className="btn btn-outline-info btn-sm" onClick={changeTableConfig}>
								{table.users.buttons[currentLanguage][tableConfig]}
							</button>
						</div>
					}

					{ (currentTabView === "pins") &&
						<div className="d-flex justify-content-end mb-2">
							<p className="my-auto mr-3">{table.pins.texts[currentLanguage].currentPin}: { pin }</p>
							<button type="button" className="btn btn-outline-info btn-sm" onClick={handlePINChange}>
								{language[currentLanguage].buttons.changePin}
							</button>
						</div>
					}

					</div>
					<Table tableId={currentTabView} data={tableData} language={currentLanguage} config={tableConfig} handleTablechanges={handleTablechanges} />
				</Col>
			)}
		</Row>
		</Container>
		<Container className="d-flex justify-content-center">
		<Row>
			<Col style={{height: "50px"}}>{message.show && <div className="alert alert-danger mt-3" role="alert">{message.text}</div>}</Col>
		</Row>
		</Container>
		</AuthContext.Provider>
	);


}