import React, {useState, useEffect} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

import Bugsnag from '@bugsnag/js'

import moment from 'moment';

import Loader from './Components/Loader';


import Header from './Pages/Header';
import Footer from './Pages/Footer';

import PageHome from './Pages/Home';
import PageContact from './Pages/Contact';
import PageDisclosure from './Pages/Disclosure';
import PageCompliance from './Pages/Compliance';
import PageSignature from './Pages/Signature';
import PageSupervision from './Pages/Supervision';

import Backend from './Pages/Backend';


import { toDBString } from './GlobalFunctions';
import ConfigContext, { language } from './ConfigContext';


const { REACT_APP_URL_DEV, REACT_APP_API_URL_DEV, REACT_APP_URL_PROD, REACT_APP_API_URL_PROD, REACT_APP_TENANT, REACT_APP_ENVIRONMENT } = process.env;

let ENVIRONMENT = REACT_APP_ENVIRONMENT;

let APP_URL = REACT_APP_URL_DEV;
let API_URL = REACT_APP_API_URL_DEV;
let TENANT = REACT_APP_TENANT;

if (ENVIRONMENT === "production") {
	APP_URL = REACT_APP_URL_PROD;
	API_URL = REACT_APP_API_URL_PROD;
}


const prevFormValues = {
	forename: "",
	surname: "",
	email: "",
};

const initialFormValues = {
	forename: "",
	surname: "",
	email: "",
	company: "",
	street: "",
	postcode: "",
	city: "",
	phone: "",
	visit_reason: "",
	appointment_location: "",
	appointment_date: new Date(),
	appointment_s_time: moment(),
	appointment_e_time: "",
	signature: "",
	visit_s_timestamp: toDBString(new Date()),// Shall not be changed, but resetted.
	visit_e_timestamp: toDBString(new Date()),// Shall be changed, when user is 'logged out' or resetted.
	supervisor_pin: "",
};

const initialFormValidation = {
	forename: "",
	surname: "",
	email: "",
	company: "",
	street: "",
	postcode: "",
	city: "",
	phone: "",
	visit_reason: "",
	appointment_location: "",
	appointment_date: "",
	appointment_s_time: "",
	appointment_e_time: "",
	signature: "",
	supervision: "",
};

const pageRef = React.createRef();
const formRef = React.createRef();

function App() {

	const [loader, setLoader] = useState(true);// The loader element on initial page load.


	// --- Language - Texts --- //
	const [currentLanguage, setLanguage] = useState("de");
	const [texts, setTexts] = useState(language);
	// --- Language - Texts --- //


	// --- Form - Values --- //
	const [stateFormValues, setFormValues] = useState(initialFormValues);// Should be useReducer
	const [stateFormValidation, setFormValidation] = useState(initialFormValidation);// Should be a useReducer
	// --- Form - Values --- //


	// --- Form --- //
	const [btDisabled, setButtonDisabled] = useState(false);//Disables the forward button
	const [clearSignature, setClearSignature] = useState(false);// Clears the signature input field
	// --- Form --- //


	// --- Form - Error handling --- //
	const [formSavedSuccess, setSuccess] = useState(false);// Hides the input buttons on signature page and shows check
	const [formSavedError, setError] = useState(false);// Displays an error message with error unknown
	// --- Form - Error handling --- //


	// --- Page --- //
	const [activeView, setActiveView] = useState("home");
	// --- Page --- //


	// --- PIN --- //
	const [pin, setPin] = useState("");
	// --- PIN --- //



	const timeFormat = 'HH:mm';

	const configContext = {
		currentLanguage,
		switchLanguage: () => {
			if (currentLanguage === "de") setLanguage("en");
			else setLanguage("de");
		},
		APP_URL,
		API_URL
	}



	useEffect(() => {
		setLanguage("de");
		// setTimeout(() => {setLoader(false)}, 500);
	}, []);


	useEffect(() => {

		fetch(API_URL+'/gettexts', {
			method: 'POST',
			body: JSON.stringify({tenant: TENANT}),
		})
		.then((response) => Promise.all([response.status, response.json()]))
		.then(([status, res]) => {

			if (status === 200 && res) {
				setTexts(res);
			}

		})
		.catch((reason) => {
			reason = "Error occured loading texts.\n" + reason;
			Bugsnag.notify(new Error(reason), function (event) {
				event.severity = 'warning';
			});


			// Do default state action for failed fetch.
			setTexts(language);
		}).finally(() => {
			setLoader(false);
		})

	}, [currentLanguage]);


	useEffect(() => {

		switch (activeView) {
			case "home":
				// --- Will update values, if user returns to homepage --- //
				initialFormValues.appointment_location = texts[currentLanguage].form.local_pre_fill;
				initialFormValues.appointment_date = new Date();
				initialFormValues.appointment_s_time = moment();

				// Meta information
				initialFormValues.visit_s_timestamp = toDBString(new Date());
				initialFormValues.visit_e_timestamp = toDBString(new Date());


				setFormValues(initialFormValues);
				setFormValidation(initialFormValidation);
				// --- Will update values, if user returns to homepage --- //
				break;
			default:
				break;
		}


		pageRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });

	}, [activeView, texts, currentLanguage]);



	const onChange = (e) => {
		setFormValues({...stateFormValues, ...{[e.target.name]: e.target.value}});
	}

	const onBlur = (e) => {

		if (prevFormValues[e.target.name] !== stateFormValues[e.target.name]) {
			if (stateFormValues.forename && stateFormValues.surname && stateFormValues.email) {
				//check if person exists already
				search();
			}
		}

	}

	const onFocus = (e) => {
		prevFormValues[e.target.name] = e.target.value;
	}




	const handleDateChange = (date) => {
		setFormValues({...stateFormValues, ...{"appointment_date": date}});
	}

	const handleTimeChange = (type, time) => {
		setFormValues({...stateFormValues, ...{[type]: time}});
	}

	const setSignature = (sig) => {
		setFormValues({...stateFormValues, ...{signature: sig}});
	}


	const handleContactData = () => {

		let validation = {};

		if (!stateFormValues.forename) validation.forename = "is-invalid";
		if (!stateFormValues.surname) validation.surname = "is-invalid";
		if (!stateFormValues.street) validation.street = "is-invalid";
		if (!stateFormValues.postcode) validation.postcode = "is-invalid";
		if (!stateFormValues.city) validation.city = "is-invalid";
		if (!stateFormValues.appointment_location) validation.appointment_location = "is-invalid";
		if (!stateFormValues.appointment_date) validation.appointment_date = "is-invalid";
		//if (!stateFormValues.signature) validation.signature = "is-invalid";

		if (!stateFormValues.email) validation.email = "is-invalid";
		if (!/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(stateFormValues.email)) validation.email = "is-invalid";
		if (!stateFormValues.phone) validation.phone = "is-invalid";

		if (!stateFormValues.appointment_s_time) validation.appointment_s_time = "is-invalid";
		if (!stateFormValues.appointment_e_time) validation.appointment_e_time = "is-invalid";

		if (!stateFormValues.visit_reason) validation.visit_reason = "is-invalid";


		setFormValidation({...initialFormValidation, ...validation});


		if (Object.keys(validation).length) {
			formRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
			return false;
		}

		setFormValues({
			...stateFormValues,
			...{dateFormat: stateFormValues.appointment_date.toISOString().slice(0, 10)},
			...{sTimeFormat: moment(stateFormValues.appointment_s_time).format(timeFormat)},
			...{eTimeFormat: moment(stateFormValues.appointment_e_time).format(timeFormat)},
		});


		setActiveView("disclosure");

	}

	const handleSignature = () => {

		let validation = {};

		if (!stateFormValues.signature) validation.signature = "is-invalid";

		setFormValidation({...initialFormValidation, ...validation});

		if (Object.keys(validation).length) {
			setError(true);
			setSuccess(false);

			return false;
		}


		if (stateFormValues.signature) {
			setError(false);
			setSuccess(true);


			setTimeout(() => {

				setError(false);
				setSuccess(false);

				setActiveView("supervision");

			}, 4000);
		}

		// return false;

	}

	const handleSupervision = () => {

		let validation = {};

		if (typeof stateFormValues.supervisor_pin === "undefined") validation.supervision = "is-invalid";
		else if (!stateFormValues.supervisor_pin) validation.supervision = "is-invalid";
		else if (stateFormValues.supervisor_pin.length !== 4) validation.supervision = "is-invalid";
		else if (isNaN(stateFormValues.supervisor_pin)) validation.supervision = "is-invalid";
		else if (stateFormValues.supervisor_pin !== pin) validation.supervision = "is-invalid";


		setFormValidation({...initialFormValidation, ...validation});

		if (Object.keys(validation).length) {
			setError(true);
			return false;
		} else {
			setError(false);
		}


		// TEMP: Set that the user logged out here.
		stateFormValues.visit_e_timestamp = toDBString(new Date());


		// After monitoring the values, they can be saved if okay.
		save();

	}


	/**
	 * This function saves all values, inputted in the form fields, into the database.
	**/
	const save = () => {


		setLoader(true);
		setButtonDisabled(true);


		fetch(API_URL+'/insert', {
			method: 'POST',
			body: JSON.stringify(stateFormValues),
		})
		.then((response) => Promise.all([response.status, response.json()]))
		.then(([status, res]) => {

			if (status === 200 && res) {
				setClearSignature(true);
				setClearSignature(false);

				setSuccess(true);
				setError(false);
			} else {
				setButtonDisabled(false);
				setError(true);
			}


			setTimeout(() => {

				if (status === 200 && res) {
					setFormValues(initialFormValues);

					setButtonDisabled(false);
					setSuccess(false);

					setActiveView("home");
				}
				else setError(false);

			}, 4000);

		})
		.catch((reason) => {
			reason = "Error occured saving form.\n" + reason;
			Bugsnag.notify(new Error(reason), function (event) {
				event.severity = 'warning';
			});


			// Do default state action for failed fetch.
			setButtonDisabled(false);
			setError(true);
		})
		.finally(() => {
			setLoader(false);
		})


	}


	/**
	 * This function performs a search after the user wrote the values forename, surname and email into the form.
	 * The result of the search function is a single user object, with which the rest of the inputs in the form will be filled.
	**/
	const search = () => {


		setLoader(true);


		fetch(API_URL+'/search', {
			method: 'POST',
			body: JSON.stringify({forename: stateFormValues.forename, surname: stateFormValues.surname, email: stateFormValues.email}),
		})
		.then((response) => Promise.all([response.status, response.json()]))
		.then(([status, res]) => {

			if (status === 200 && res) {

				if (Object.keys(res).length) {
					const resValues = {
						street: res.street,
						postcode: res.zip,
						city: res.city,
						company: res.company,
						phone: res.phone,
						visit_reason: res.visit_reason
					}

					setFormValues({...stateFormValues, ...resValues});
				}

			}

		})
		.catch((reason) => {
			reason = "Error occured searching.\n" + reason;
			Bugsnag.notify(new Error(reason), function (event) {
				event.severity = 'warning';
			});


			// Do default state action for failed fetch.
		})
		.finally(() => {
			setLoader(false);
		})


	}


	// --- PIN --- //
	useEffect(() => {

		if (pin === "") {

			getPin(API_URL);

		}

	}, [pin]);


	const getPin = (API_URL) => {

		fetch(API_URL+'/get/pin/', {
			method: 'GET',
		})
		.then((response) => Promise.all([response.status, response.json()]))
		.then(([status, res]) => {

			if (status === 200 && res) {

				setPin(res.p_value);

			}

		})
		.catch((reason) => {
			reason = "Error occured getting pin.\n" + reason;
			Bugsnag.notify(new Error(reason), function (event) {
				event.severity = 'warning';
			});


			// Do default state action for failed fetch.
		});

	}
	// --- PIN --- //



	return (
		<ConfigContext.Provider value={configContext}>
		<div className="d-flex flex-column" style={{minHeight: "100vh"}} ref={pageRef}>

			<Loader active={loader} />

			<Header texts={texts} />

			{ (activeView === "home") &&
				<PageHome texts={texts} setActiveView={setActiveView} />
			}

			{ (activeView === "contact") &&
				<PageContact texts={texts} setActiveView={setActiveView} handleContactData={handleContactData} stateFormValidation={stateFormValidation} stateFormValues={stateFormValues} onChange={onChange} onBlur={onBlur} onFocus={onFocus} formRef={formRef} handleDateChange={handleDateChange} handleTimeChange={handleTimeChange} timeFormat={timeFormat} />
			}

			{ (activeView === "disclosure") &&
				<PageDisclosure texts={texts} setActiveView={setActiveView} />
			}

			{ (activeView === "compliance") &&
				<PageCompliance texts={texts} setActiveView={setActiveView} />
			}

			{ (activeView === "signature") &&
				<PageSignature texts={texts} setActiveView={setActiveView} handleSignature={handleSignature} stateFormValidation={stateFormValidation} getSignature={setSignature} clearSignature={clearSignature} formSavedSuccess={formSavedSuccess} setSuccess={setSuccess} formSavedError={formSavedError} setError={setError} btDisabled={btDisabled}/>
			}

			{ (activeView === "supervision") &&
				<PageSupervision texts={texts} setActiveView={setActiveView} handleSupervision={handleSupervision} stateFormValidation={stateFormValidation} stateFormValues={stateFormValues} onChange={onChange} formSavedSuccess={formSavedSuccess} setSuccess={setSuccess} formSavedError={formSavedError} setError={setError} btDisabled={btDisabled} />
			}

			{ (activeView === "backend") &&
				<Backend texts={texts} setActiveView={setActiveView} pin={pin} getPin={getPin} />
			}

			<Footer texts={texts} url={APP_URL} />

		</div>
		</ConfigContext.Provider>
	);
}

export default App;