import React from "react";
import { forwardRef } from 'react';
import MaterialTable from "material-table";


import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';


import {tableConfigLanguage, table} from '../ConfigContext';


const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};



export default function Table(props) {

  const {language, tableId, config} = props
  const [tableColumns, setTableColumns] = React.useState([]);

  const [allData, setData] = React.useState([]);

  React.useEffect(() => {

    setData(props.data)
    //console.log(props.data)

  }, [props])


  React.useEffect(() => {

    let table_ = table[tableId].lng[language]

    table_.forEach((elem, key) => {
      table_[key] = { ...elem , ...table[tableId].config[config][key] }
    });

    setTableColumns(table_)


  }, [config, tableId, language])

  let editable = {}
  if (tableId === "admins") {
    editable = {
      onRowAddCancelled: rowData => console.log('Row adding cancelled'),
      onRowUpdateCancelled: rowData => console.log('Row editing cancelled'),
      onRowAdd: newData =>
          new Promise((resolve, reject) => {

              props.handleTablechanges("insert", newData, [], allData, resolve, reject)

          }),
      onRowUpdate: (newData, oldData) =>
          new Promise((resolve, reject) => {

              props.handleTablechanges("update", newData, oldData, allData, resolve, reject)

          }),
      onRowDelete: oldData =>
          new Promise((resolve, reject) => {

              props.handleTablechanges("delete", [], oldData, allData, resolve, reject)

          })
    }
  }

  return (
    <MaterialTable
      icons={tableIcons}
      columns={tableColumns}
      data={allData}
      title=""

      options={{
        exportButton: true,
        search: true,
        rowStyle: {
          whiteSpace: 'nowrap',
        },
        headerStyle: {
          whiteSpace: 'nowrap',
        }
      }}

      localization={{
        pagination: {
          labelDisplayedRows: tableConfigLanguage[language].pagination.labelDisplayedRows,
          labelRowsSelect: tableConfigLanguage[language].pagination.labelRowsSelect,
          firstTooltip: tableConfigLanguage[language].pagination.firstTooltip,
          previousTooltip: tableConfigLanguage[language].pagination.previousTooltip,
          nextTooltip: tableConfigLanguage[language].pagination.nextTooltip,
          lastTooltip: tableConfigLanguage[language].pagination.lastTooltip,
        },
        toolbar: {
          searchTooltip: tableConfigLanguage[language].toolbar.searchTooltip,
          searchPlaceholder: tableConfigLanguage[language].toolbar.searchPlaceholder,
          exportTitle: tableConfigLanguage[language].toolbar.exportTitle,
          exportCSVName: tableConfigLanguage[language].toolbar.exportCSVName,
          exportPDFName: tableConfigLanguage[language].toolbar.exportPDFName,

        },
        body: {
          emptyDataSourceMessage: tableConfigLanguage[language].body.emptyDataSourceMessage,
          editRow: {
            deleteText: tableConfigLanguage[language].body.editRow.deleteText,
            cancelTooltip: tableConfigLanguage[language].body.editRow.cancelTooltip,
            saveTooltip: tableConfigLanguage[language].body.editRow.saveTooltip
          },
          addTooltip: tableConfigLanguage[language].body.addTooltip,
          deleteTooltip: tableConfigLanguage[language].body.deleteTooltip,
          editTooltip: tableConfigLanguage[language].body.editTooltip
        }
      }}

      editable = {editable}
    />
  );

}