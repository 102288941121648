import React from "react";
import { Container, Row, Col } from 'react-bootstrap';
import 'antd/dist/antd.min.css';
import { TimePicker, ConfigProvider } from 'antd';
import parse from 'html-react-parser';

import DatePicker from '../Components/Datepicker';

import locale_de from 'antd/es/locale/de_DE';
import locale_en from 'antd/es/locale/en_US';


import ConfigContext from '../ConfigContext';


export default function Page(props) {

	const {texts, handleContactData, formRef, stateFormValidation, stateFormValues, setActiveView, onChange, onBlur, onFocus, handleDateChange, handleTimeChange, timeFormat} = props;

	const { currentLanguage } = React.useContext(ConfigContext);

	const language = texts;


	let locale = locale_de
	if (currentLanguage === "en") locale = locale_en


	return (
		<Container className="mt-4 flex-grow-1">
			<Row>
				<Col className="text-left d-flex">
					<div><button type="button" className="btn btn-secondary btn-sm" onClick={() => setActiveView("home")}>&lt;</button></div>
					<div className="ml-3 w-100"><h4>{language[currentLanguage].titles.contact}</h4></div>
				</Col>
			</Row>
			<Row className="pt-4">
				<Col>
					<p>{parse(language[currentLanguage].texts.contact)}</p>
				</Col>
			</Row>
			<Row>
				<Col className="text-center mt-4" ref={formRef}>

					<div className="form-group d-flex flex-wrap flex-md-nowrap">
						<input type="text" className={"form-control " + stateFormValidation.forename} placeholder={language[currentLanguage].form.forename} name="forename" value={stateFormValues.forename} onChange={onChange} onBlur={onBlur} onFocus={onFocus} autoComplete="none" />
						<input type="text" className={"form-control mt-3 mt-md-0 ml-0 ml-md-3 " + stateFormValidation.surname} placeholder={language[currentLanguage].form.surname} name="surname" value={stateFormValues.surname} onChange={onChange} onBlur={onBlur} onFocus={onFocus} autoComplete="none" />
					</div>

					<div className="form-group"><input type="text" className={"form-control " + stateFormValidation.email} placeholder={language[currentLanguage].form.email} name="email" value={stateFormValues.email} onChange={onChange} onBlur={onBlur} onFocus={onFocus} autoComplete="none" /></div>

					<div className="form-group"><input type="text" className={"form-control " + stateFormValidation.company} placeholder={language[currentLanguage].form.company} name="company" value={stateFormValues.company} onChange={onChange} autoComplete="none" /></div>

					<div className="form-group"><input type="text" className={"form-control " + stateFormValidation.street} placeholder={language[currentLanguage].form.street} name="street" value={stateFormValues.street} onChange={onChange} autoComplete="none" /></div>

					<div className="form-group d-flex flex-wrap flex-sm-nowrap">
						<input type="text" className={"form-control postalcode " + stateFormValidation.postcode} placeholder={language[currentLanguage].form.postcode} name="postcode" value={stateFormValues.postcode} onChange={onChange} autoComplete="none" />
						<input type="text" className={"form-control mt-3 mt-sm-0 ml-0 ml-sm-3 " + stateFormValidation.city} placeholder={language[currentLanguage].form.city} name="city" value={stateFormValues.city} onChange={onChange} autoComplete="none" />
					</div>



					<div className="form-group"><input type="text" className={"form-control " + stateFormValidation.phone} placeholder={language[currentLanguage].form.phone} name="phone" value={stateFormValues.phone} onChange={onChange} autoComplete="none" /></div>

					<div className="form-group"><input type="text" className={"form-control " + stateFormValidation.visit_reason} placeholder={language[currentLanguage].form.visit_reason} name="visit_reason" value={stateFormValues.visit_reason} onChange={onChange} autoComplete="none" /></div>


					<div className="form-group d-flex flex-wrap flex-md-nowrap">
						<input type="text" className={"form-control " + stateFormValidation.appointment_location} placeholder={language[currentLanguage].form.appointment_location} name="appointment_location" value={stateFormValues.appointment_location} onChange={onChange} />
						<div className="date-picker mt-3 mt-md-0 ml-0 ml-md-3">
							<DatePicker language={currentLanguage} placeholder={language[currentLanguage].form.appointment_date} startDate={stateFormValues.appointment_date} handleChange={handleDateChange} error={stateFormValidation.appointment_date} />
						</div>
						<ConfigProvider locale={locale}>
							<div className="date-picker mt-3 mt-md-0 ml-0 ml-md-3">
								<TimePicker className={"form-control " + stateFormValidation.appointment_s_time} value={stateFormValues.appointment_s_time} format={timeFormat} onChange={(value) => {handleTimeChange("appointment_s_time", value)}} placeholder={language[currentLanguage].form.appointment_s_time} />
							</div>
							<div className="date-picker mt-3 mt-md-0 ml-0 ml-md-3">
								<TimePicker className={"form-control " + stateFormValidation.appointment_e_time} value={stateFormValues.appointment_e_time} format={timeFormat} onChange={(value) => {handleTimeChange("appointment_e_time", value)}} placeholder={language[currentLanguage].form.appointment_e_time} />
							</div>
						</ConfigProvider>
					</div>

				</Col>
			</Row>
			<Row className="justify-content-center">
				<Col className="mt-4" xs={12} sm={6} lg="auto">
					<button type="button" className="btn btn-primary w-100" onClick={handleContactData}>{language[currentLanguage].buttons.next}</button>
				</Col>
			</Row>
		</Container>
	);

}