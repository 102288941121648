import React from "react";
import DatePicker from "react-datepicker";
import { registerLocale, setDefaultLocale } from  "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

import de from 'date-fns/locale/de';

registerLocale('de', de)


// CSS Modules, react-datepicker-cssmodules.css
// import 'react-datepicker/dist/react-datepicker-cssmodules.css';

class Dpicker extends React.Component {
  state = {
    startDate: this.props.startDate,
    locale: "de",
    dateFormat: {
      de: "dd.MM.yyyy",
      en: "MM/dd/yyyy",
    }
  };

  handleChange = date => {
    this.setState({
      startDate: date
    }, () => {
      this.props.handleChange(this.state.startDate)
    });
  };

  componentDidMount() {
    setDefaultLocale(this.state.locale)
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.language !== this.state.locale) {
      this.setState({locale: this.props.language})
      setDefaultLocale(this.state.locale)

    }

    if (this.props.startDate !== this.state.startDate) {
      this.setState({
        startDate: this.props.startDate
      })
    }
  }

  render() {
    return (
      <DatePicker
        selected={this.state.startDate}
        onChange={this.handleChange}
        locale={this.state.locale}
        dateFormat={this.state.dateFormat[this.props.language]}
        className={"form-control w-100 " + this.props.error}
        placeholderText={this.props.placeholder}
        disabled
      />
    );
  }
}

export default Dpicker;