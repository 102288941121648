import React from "react";
import QRCode from "react-qr-code";


export default function QR(props) {

  return (
      <QRCode
      value={props.url}
      size={100}
    />
  );

}