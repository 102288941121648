import React from "react";
import { Container, Row, Col } from 'react-bootstrap';
import parse from 'html-react-parser';

import SignPad from '../Components/SignatureCanvas';
import CheckMark from '../Components/CheckMark';


import ConfigContext from '../ConfigContext';


export default function Page(props) {

	const {texts, setActiveView, stateFormValidation, handleSignature, getSignature, clearSignature, formSavedSuccess, formSavedError, btDisabled} = props;

	const { currentLanguage } = React.useContext(ConfigContext);

	const language = texts;

	return (
		<Container className="mt-4 flex-grow-1">
			<Row>
				<Col className="text-left d-flex">
					<div><button type="button" className="btn btn-secondary btn-sm" onClick={() => setActiveView("compliance")}>&lt;</button></div>
					<div className="ml-3"><h4>{language[currentLanguage].titles.signature}</h4></div>
				</Col>
			</Row>
			<Row className="pt-4">
				<Col>
					<p>{parse(language[currentLanguage].texts.signature)}</p>
				</Col>
			</Row>
			<Row>
				<Col className="text-left pt-3">
					<p>{language[currentLanguage].form.signature}</p>
				</Col>
			</Row>
			<Row>
				<Col>
					<SignPad width={420} height={200} color={'black'} btClear={language[currentLanguage].buttons.clear} getsignature={getSignature} error={stateFormValidation.signature} clear={clearSignature} />
				</Col>
			</Row>
			{ formSavedError &&
				<Row>
					<Col>
						<div className="alert alert-danger mt-3" role="alert">{language[currentLanguage].error.unknown}</div>
					</Col>
				</Row>
			}
			<Row className="justify-content-center">
				<Col className="mt-4" xs={12} sm={6} lg="auto">
					{ formSavedSuccess
						?
						<CheckMark />
						:
						<button type="button" className="btn btn-primary w-100" onClick={handleSignature} disabled={btDisabled}>{language[currentLanguage].buttons.next}</button>
					}
				</Col>
			</Row>
		</Container>
	);

}