/**
 * Function which will convert a date from JS Date object into a JSON format styled date string, which can be inserted into the database.
 *
 * @param {Date | String} date The date that should be formatted.
 *
 * @returns {String} A string in correct format for insertion into database.
 *
 *
 * @author Maik Ratte
 * @version 20.03.2024
**/
export function toDBString(date) {
	let res_str = "";


	if (typeof date === 'string' || date instanceof String) {
		// If date is of type string convert it into date object.
		date = new Date(date);
	}


	// Convert into JSON format date string.
	let json_date = date.toJSON();

	// Split the components of the JSON format date string into separate parts.
	json_date = json_date.split("T");
	json_date[1] = json_date[1].split(".")[0];


	// Put components together into a new string.
	res_str = json_date[0] + " " + json_date[1];

	return res_str;
}

/**
 * Function which will convert a date from JS Date object into a specifc format date string.
 *
 * @param {Date | String} date The date that should be formatted.
 * @param {String} lang The lanuage in which the string should be formatted.
 *
 * @returns {String} A string in correct format for insertion into database.
 *
 *
 * @author Maik Ratte
 * @version 20.03.2024
**/
export function formatDate(date, lang = "de") {
	let res_str = "";

	let format = ".";
	if (lang !== "de") format = "/";


	if (typeof date === 'string' || date instanceof String) {
		// If date is of type string convert it into date object.
		date = new Date(date);
	}


	// Convert into JSON format date string.
	let json_date = date.toJSON();

	// Split the components of the JSON format date string into separate parts.
	json_date = json_date.split("T");
	json_date = json_date[0].split("-");


	// Correct order for dates.
	json_date.reverse();


	// Put components together into a new string.
	res_str = json_date.join(format);

	return res_str;
}