import React from "react";
import { Container, Row, Col } from 'react-bootstrap';
import parse from 'html-react-parser';

import CheckMark from '../Components/CheckMark';


import { formatDate } from '../GlobalFunctions';
import ConfigContext from '../ConfigContext';


export default function Page(props) {

	const {texts, setActiveView, onChange, handleSupervision, stateFormValidation, stateFormValues, formSavedSuccess, setSuccess, formSavedError, setError, btDisabled} = props;

	const { currentLanguage } = React.useContext(ConfigContext);

	const language = texts;


	const onClickAbort = (e) => {
		setActiveView("contact");

		stateFormValues.signature = "";
		stateFormValues.supervisor_pin = "";


		setError(false);
		setSuccess(false);
	}


	return (
		<Container className="mt-4 flex-grow-1">
			<Row>
				<Col className="text-left d-flex">
					<div><h4>{language[currentLanguage].titles.supervision}</h4></div>
				</Col>
			</Row>
			{ (typeof language[currentLanguage].texts.supervision_section_1 !== "undefined") &&
				<Row className="pt-4">
					<Col>
						<p>{parse(language[currentLanguage].texts.supervision_section_1)}</p>
					</Col>
				</Row>
			}
			<Row className="pb-4">
				<Col className="text-left pt-3">
					<table className="MuiTable-root w-100" style={{borderSpacing: 0, borderCollapse: "collapse"}}>
						<tbody className="d-table-row-group">
							{ Object.keys(stateFormValues).map( (valueKey, value) => {
								if (valueKey === "visit_s_timestamp" || valueKey === "visit_e_timestamp" || valueKey === "supervisor_pin") return null;
								else if (typeof stateFormValues[valueKey] === "object" && !Array.isArray(stateFormValues[valueKey]) && stateFormValues[valueKey] !== null) return null;
								else {

									let lang_key = valueKey;
									if (valueKey === "dateFormat") lang_key = 'appointment_date';
									if (valueKey === "sTimeFormat") lang_key = 'appointment_s_time';
									if (valueKey === "eTimeFormat") lang_key = 'appointment_e_time';

									let value = stateFormValues[valueKey];
									if (valueKey === "dateFormat") value = formatDate(stateFormValues[valueKey], currentLanguage);

									return (
										<tr key={ valueKey } className="d-table-row" style={{outline: 0, verticalAlign: "middle"}}>
											<td key={ valueKey+"_desc" } className="d-table-cell text-left" style={{padding: "16px", boxSizing: "border-box", borderBottom: "1px solid rgba(224, 224, 224, 1)"}}>{ language[currentLanguage].form[lang_key] }</td>
											<td key={ valueKey+"_value" } className="d-table-cell text-left" style={{padding: "16px", boxSizing: "border-box", borderBottom: "1px solid rgba(224, 224, 224, 1)"}}>
												{((valueKey === "signature") ?
													<img style={{ height: "auto", width: "auto", maxHeight: "50px" }} src={ value } alt="" />
													:
													value
												)}
											</td>
										</tr>
									);

								}
							}) }
						</tbody>
					</table>
				</Col>
			</Row>
			<Row className="pt-4">
				{ (typeof language[currentLanguage].texts.supervision_section_2 !== "undefined") &&
					<Col xs={12}>
						{parse(language[currentLanguage].texts.supervision_section_2)}
					</Col>
				}
				<Col className="text-center mt-2" xs={12}>
					<div className="form-group">
						<input type="text" className={"form-control " + stateFormValidation.supervision} placeholder="1234" name="supervisor_pin" value={stateFormValues.supervisor_pin} onChange={(e) => {onChange(e); stateFormValidation.supervision = ""; setError(false);}} autoComplete="none" />
					</div>
				</Col>
			</Row>
			{ formSavedError &&
				<Row>
					<Col>
						<div className="alert alert-danger mt-3" role="alert">{language[currentLanguage].error.unknown}</div>
					</Col>
				</Row>
			}
			{ formSavedSuccess
				?
				<Row className="justify-content-center">
					<Col className="mt-4" xs={12} sm={6} lg="auto">
						<CheckMark />
					</Col>
				</Row>
				:
				<Row className="justify-content-between">
					<Col className="mt-4" xs={12} sm={6} lg="auto">
						<button type="button" className="btn btn-danger w-100" onClick={onClickAbort} disabled={btDisabled}>{language[currentLanguage].buttons.abort}</button>
					</Col>
					<Col className="mt-4 mt-sm-auto" xs={12} sm={6} lg="auto">
						<button type="button" className="btn btn-primary w-100" onClick={handleSupervision} disabled={btDisabled}>{language[currentLanguage].buttons.save}</button>
					</Col>
				</Row>
			}
		</Container>
	);

}