import * as React from 'react';
import { Container, Row, Col } from 'react-bootstrap';


import ConfigContext from '../ConfigContext';


export default function Page(props) {

	const {texts, setActiveView} = props;

	const { currentLanguage } = React.useContext(ConfigContext);

	const language = texts;

	return (
		<Container className="d-flex flex-column flex-grow-1 justify-content-center start-view">
			<Row className="mb-3">
				<Col className="text-center">
					<button type="button" className="btn btn-primary" onClick={() => setActiveView("contact")}>
						{language[currentLanguage].buttons.newVisit}
					</button>
				</Col>
			</Row>

			<Row>
				<Col className="text-center">
					<button type="button" className="btn btn-danger" onClick={() => setActiveView("backend")}>
						{language[currentLanguage].buttons.admin}
					</button>
				</Col>
			</Row>
		</Container>
	);

}