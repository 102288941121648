import { branch, revision, version } from './version';

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';

import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import BugsnagPerformance from '@bugsnag/browser-performance'


import App from './App';
import * as serviceWorker from './serviceWorker';


const { REACT_APP_BUGSNAG_API_KEY, REACT_APP_ENVIRONMENT } = process.env;

let ENVIRONMENT = REACT_APP_ENVIRONMENT;

let BUGSNAG_API_KEY = REACT_APP_BUGSNAG_API_KEY;


if (!window.location.port && typeof window.__REACT_DEVTOOLS_GLOBAL_HOOK__ === 'object') {
	window.__REACT_DEVTOOLS_GLOBAL_HOOK__.inject = function () {}
}


if (BUGSNAG_API_KEY && BUGSNAG_API_KEY.trim() !== "") {
	// Start BugSnag first...
	Bugsnag.start({
		apiKey: BUGSNAG_API_KEY,
		plugins: [new BugsnagPluginReact()],
		enabledReleaseStages: ['development', 'staging', 'production'],
		releaseStage: ENVIRONMENT,
		appVersion: version,
		metadata: {
			version: { version, branch, revision }
		},
	});

	if (ENVIRONMENT !== "production") {
		// ... start Perfomance report as well ...
		BugsnagPerformance.start({
			apiKey: BUGSNAG_API_KEY,
			releaseStage: ENVIRONMENT,
			appVersion: version,
			metadata: {
				version: { version, branch, revision }
			},
		});
	}


	// Create the error boundary...
	var ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

	// Interesting to read about error handling:
	// @link https://www.developerway.com/posts/how-to-handle-errors-in-react
}


ReactDOM.render(
	<React.StrictMode>
		{((BUGSNAG_API_KEY && BUGSNAG_API_KEY.trim() !== "")
			?
			<ErrorBoundary>
				<App />
			</ErrorBoundary>
			:
			<App />
		)}
	</React.StrictMode>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
