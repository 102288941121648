import React from "react";
import { Container, Row, Col } from 'react-bootstrap';
import parse from 'html-react-parser';


import ConfigContext from '../ConfigContext';


export default function Page(props) {

	const {texts, setActiveView} = props;

	const { currentLanguage } = React.useContext(ConfigContext);

	const language = texts;

	return (
		<Container className="mt-4 flex-grow-1">
			<Row>
				<Col className="text-left d-flex">
					<div><button type="button" className="btn btn-secondary btn-sm" onClick={() => setActiveView("contact")}>&lt;</button></div>
					<div className="ml-3"><h4>{language[currentLanguage].titles.disclosure_main}</h4></div>
				</Col>
			</Row>
			<Row className="pt-4">
				<Col>
					<p className="disclosure-text">{parse(language[currentLanguage].texts.disclosure)}</p>
				</Col>
			</Row>
			<Row className="justify-content-center">
				<Col className="mt-4" xs={12} sm={6} lg="auto">
					<button type="button" className="btn btn-primary w-100" onClick={() => setActiveView("compliance")}>{language[currentLanguage].buttons.accept}</button>
				</Col>
			</Row>
		</Container>
	);

}