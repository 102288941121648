import * as React from 'react';
import { Container, Row, Col } from 'react-bootstrap';


import ConfigContext from '../ConfigContext';


let logo;
import(`../Assets/${process.env.REACT_APP_TENANT}/logo.png`).then((module) => {
	logo = module.default;
});


export default function Header(props) {

	const {texts} = props

	const { currentLanguage, switchLanguage } = React.useContext(ConfigContext);

	const language = texts;

	return (
		<Container>
			<Row>
				<Col><button type="button" className="btn btn-outline-secondary mt-2" onClick={switchLanguage}>{language[currentLanguage].language.name}</button></Col>
				<Col><div className="float-right mr-2 mt-2"><img src={logo} className="logo" alt="" /></div></Col>
			</Row>
		</Container>
	);

}